






































































import { Component, Vue } from 'vue-property-decorator'
import BaseIcon from '@/components/base/BaseIcon.vue'
import LandingTitle from "@/modules/LandingPageHome/components/LandingTitle.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import LandingModal from "@/modules/LandingPageHome/components/LandingModal.vue";
import LandingFeedbackForm from "@/modules/LandingPageHome/components/LandingFeedbackForm.vue";

@Component({ name: 'LandingFooter', components: {LandingFeedbackForm, LandingModal, BaseButton, LandingTitle, BaseIcon } })
export default class LandingFooter extends Vue {

  isShowModal = false

  links = []
}
