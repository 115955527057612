import axios from '@/api/axios'
import {Partner} from "@/api/CommonWrapper/types";

class LandingPageService {
  async getOurPartners(): Promise<Partner[]> {
    try {
      const response = await axios.get('/api/b2b/v1/partners/our')
      return response.data.data
    } catch (error) {
      console.log(error)
      return error.response.data
    }
  }

}

export default new LandingPageService()
