import axios from '@/api/axios'
import { BookingModel } from '@/core/models/BookingModel'

class BookingService {
  async getBookings(
    from: Nullable<Date> = null,
    to: Nullable<Date> = null
  ): Promise<BookingModel[]> {
    try {
      const response = await axios.get('api/b2b/v1/bookings', {
        params: {
          from: from ? from.toISOString() : from,
          to: to ? to.toISOString() : to,
        },
      })
      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('getBookings', error.message, error.response?.data)
      }
      throw error
    }
  }

  async addBooking(
    placeId: number,
    start: Date,
    end: Date
  ): Promise<BookingModel> {
    try {
      const response = await axios.post('api/b2b/v1/bookings', {
        place_id: placeId,
        start: start.toISOString(),
        end: end.toISOString(),
      })
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('addBooking', error.message, error.response?.data)
      }
      throw error
    }
  }

  async updateBooking(
    id: number,
    placeId: number,
    start: Date,
    end: Date
  ): Promise<BookingModel> {
    try {
      const response = await axios.post(`api/b2b/v1/bookings/${id}`, {
        place_id: placeId,
        start: start.toISOString(),
        end: end.toISOString(),
      })
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('updateBooking', error.message, error.response?.data)
      }
      throw error
    }
  }

  isServiceError = axios.isAxiosError
}

export default new BookingService()
