import axios from '@/api/axios'
import { setItem } from '@/utils/persistanceStorage'
import { LoginModel } from './models/LoginModel'
import { RegisterModel } from './models/RegisterModel'
import { ResetModel } from './models/ResetModel'
import { serialize } from 'object-to-formdata'

class AuthService {
  async login(credentials: LoginModel) {
    try {
      const response = await axios.post('/api/b2b/v1/auth/login', credentials)
      if (response.headers.authorization) {
        setItem('token', response.headers.authorization)
      }

      return response.data
    } catch (error) {
      return false
    }
  }

  async logout() {
    const response = await axios.post('/api/b2b/v1/auth/logout')
    console.log(response)

    localStorage.removeItem('token')
  }

  async register(credentials: RegisterModel): Promise<any> {
    try {
      return await axios.post('/api/b2b/v1/register', credentials)
    } catch (error) {
      return error.response.data
    }
  }

  async resetPassword(data: ResetModel): Promise<any> {
    try {
      return await axios.post(
        '/api/b2b/v1/auth/reset',
        serialize(data, { nullsAsUndefineds: true }),
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
    } catch (error) {
      return error.response.data
    }
  }

  async remindPassword(data: any): Promise<any> {
    try {
      return await axios.post(
        '/api/b2b/v1/auth/forgot',
        serialize(data, { nullsAsUndefineds: true }),
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
    } catch (error) {
      return error.response.data
    }
  }

  async checkResetToken(
    token: string | (string | null)[]
  ): Promise<string | null> {
    if (token) {
      return axios
        .post('/api/b2b/v1/auth/reset/checkToken', { token: token })
        .then((response) => {
          if (response.data.email) {
            return response.data.email
          } else {
            return null
          }
        })
        .catch(() => {
          return null
        })
    }

    return null
  }

  async refreshToken() {
    try {
      const response = await axios.post('/api/b2b/v1/auth/refresh')
      console.log(response)
    } catch (error) {
      console.log(error)
    }
  }
}

export default new AuthService()
