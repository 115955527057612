import { getItem } from '@/utils/persistanceStorage'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
// import LandingPage from '../modules/LandingPage/LandingPage.vue'
// import LandingPage from '../modules/LandingPageNew/LandingPage.vue'
import LandingPageHome from '../modules/LandingPageHome/LandingPage.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: LandingPageHome,
  },
  {
    path: '/home',
    name: 'Home',
    component: LandingPageHome,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../modules/AuthPage/LoginPage.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../modules/AuthPage/ResetPasswordPage.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../modules/AuthPage/ForgotPasswordPage.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../modules/AuthPage/RegisterPage.vue'),
    meta: {
      layout: 'auth',
    },
  },

  {
    path: '/my-rooms',
    name: 'MyRooms',
    component: () => import('../modules/MyRoomsPage/MyRoomsPage.vue'),
    meta: {
      layout: 'profile',
    },
  },
  {
    path: '/my-locations',
    name: 'MyLocations',
    component: () => import('../modules/MyLocationsPage/MyLocationsPage.vue'),
    meta: {
      layout: 'profile',
    },
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../modules/NewsPage/NewsPage.vue'),
    meta: {
      layout: 'profile',
    },
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import('../modules/CalendarPage/CalendarPage.vue'),
    meta: {
      layout: 'sticky',
    },
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('../modules/EventsPage/EventsPage.vue'),
    meta: {
      layout: 'profile',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../modules/ProfilePage/ProfilePage.vue'),
    meta: {
      layout: 'profile',
    },
  },
  {
    path: '/premium',
    name: 'Premium',
    component: () => import('../modules/PremiumPage/PremiumPage.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    path: '/statistics',
    name: 'Dashboards',
    component: () => import('../modules/DashboardsPage/DashboardsPage.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    path: '/tg/:id',
    name: 'Telegram',
    component: () => import('../modules/TelegramConfirm/TelegramConfirm.vue'),
    meta: {
      layout: 'profile',
    },
    props: true,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name !== from.name) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0, behavior: 'smooth' }
      }
    }
  },
})

router.beforeEach((to, from, next) => {
  const publicPages = [
    '/forgot-password',
    '/reset-password',
    '/login',
    '/register',
    '/',
  ]
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = getItem('token')

  if (authRequired && !loggedIn) {
    next('/login' + '?redirect_to=' + encodeURI(to.path))
  } else {
    next()
  }
})

export default router
