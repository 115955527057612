import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

import StatististicsService from '@/modules/DashboardsPage/StatististicsService'

@Module({
  namespaced: true,
})
export default class StatististicsStore extends VuexModule {
  private statististics: any | null = null
  private premiumStatististics: any | null = null
  private bannerStatististics: any | null = null

  @Mutation
  private setStatististics(data: any) {
    this.statististics = data
  }

  @Mutation
  private setBannerStatististics(data: any) {
    this.bannerStatististics = data
  }

  @Mutation
  private setPremiumStatististics(data: any) {
    this.premiumStatististics = data
  }

  @Action({ commit: 'setStatististics' })
  getStatististics(): Promise<any> {
    return StatististicsService.getStatististics()
  }

  @Action({ commit: 'setBannerStatististics' })
  getBannerStatististics(id: any): Promise<any> {
    return StatististicsService.getBannerStatististics(id)
  }

  @Action({ commit: 'setPremiumStatististics' })
  getPremiumStatististics(id: any): Promise<any> {
    return StatististicsService.getPremiumStatististics()
  }

  get statististicsList(): any {
    return this.statististics
  }

  get premiumStatististicsList(): any {
    return this.premiumStatististics
  }

  get bannerStatististicsList(): any {
    return this.bannerStatististics
  }
}
