






























































import {Component, Vue} from 'vue-property-decorator'
import LandingTitle from './LandingTitle.vue'
import BaseButton from '@/components/base/BaseButton.vue'

@Component({
  name: 'LandingCommission',
  components: {
    LandingTitle,
    BaseButton,
  },
})
export default class LandingCommission extends Vue {

}
