










































import {Component, Vue} from 'vue-property-decorator'

import BaseButton from '@/components/base/BaseButton.vue'
import LandingTitle from './LandingTitle.vue'

@Component({name: 'LandingInfo', components: {LandingTitle, BaseButton}})
export default class LandingInfo extends Vue {
  list = [
    {
      id: 1,
      name: 'Зарегистрируйтесь',
      text: 'и заполняйте максимальное количество информации об объекте, подписывайтесь на телеграм бот.',
      icon: 'profile.svg',
    },
    {
      id: 2,
      name: 'Пополните счет',
      text: 'в вашем личном кабинете. Деньги за тариф спишутся автоматически.',
      icon: 'notes.svg',
    },
    {
      id: 3,
      name: 'Подключите премиум тариф',
      text: 'для быстрого достижения эффекта - оплатите премиум тариф(по желанию).',
      icon: 'crown.svg',
    },
    {
      id: 4,
      name: 'Получайте клиентов',
      text: 'звонки и заявки от клиентов DЕЛИ<span class="text-red">SPACE.</span>',
      icon: 'clients.svg',
    },
  ]
}
