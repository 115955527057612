import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import LocationsService from '@/core/services/LocationsService'
import { LocationsModel } from '@/core/models/LocationsModel'
import { GpsCoordsModel, LocationModel } from '@/core/models/LocationModel'

@Module({
  namespaced: true,
})
export default class LocationsStore extends VuexModule {
  private locations: LocationsModel[] = []
  // private recommendedLocations: any = null

  @Mutation
  private setLocations(locations: LocationsModel[]) {
    this.locations = locations
  }

  // @Mutation
  // private setRecommendedLocations(locations: []) {
  //   this.recommendedLocations = locations
  // }

  @Action({ commit: 'setLocations' })
  getLocations(): Promise<LocationsModel[]> {
    return LocationsService.getLocations()
  }

  @Action
  async getRecommendedLocations(payload: any): Promise<any> {
    return await LocationsService.getRecommendedLocations(
      payload.query,
      payload.cityName
    )
  }

  @Action
  async getLocation(id: number): Promise<LocationModel> {
    const location = await LocationsService.getLocation(id)
    const gpsCoords = new GpsCoordsModel()
    gpsCoords.lat = location.gps_coords.lat
    gpsCoords.lng = location.gps_coords.lng

    return {
      id: location.id,
      name: location.name,
      address: location.address,
      addressLegacy: location.address_legacy,
      phone: location.phone,
      whatsapp: location.whatsapp,
      telegram: location.telegram,
      active: location.active,
      parkingInfo: location.parking_info,
      wayDirections: location.way_directions,
      cityId: location.city_id,
      areaId: location.area_id,
      gpsCoords: gpsCoords,
      metroStations: location.metro_stations,
    }
  }

  get locationList(): LocationsModel[] {
    return this.locations
  }
}
