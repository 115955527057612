
































































































import {Component, Vue} from 'vue-property-decorator'
import breakpoints from '@/plugins/breakpoints'

import LandingInfo from './LandingInfo.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import {Site} from "@/api/CommonWrapper/types";

@Component({
  name: 'LandingHeader',
  components: {LandingInfo, BaseButton, BaseIcon},
})
export default class LandingHeader extends Vue {

  sites: Site[] = []

  breakpoints = breakpoints

  private async created() {
    let querySites = await this.$api.common.getSitesList()
    this.sites = querySites.data.filter(function (item) {
      return item.id !== 1
    })
  }

}
