import axios from '@/api/axios'
import { CityModel } from '@/core/models/CityModel'

class CitiesService {
  async getCities(): Promise<CityModel[]> {
    try {
      const response = await axios.get('api/b2b/v1/cities')
      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('getCities', error.message, error.response?.data)
      }
      throw error
    }
  }

  isServiceError = axios.isAxiosError
}

export default new CitiesService()
