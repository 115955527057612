import { AxiosInstance, AxiosResponse } from 'axios'
import { Params, Data } from '@/api/types'

import { gClientIntegration, yClientIntegration } from './types'

export * from './types'

export class IntegrationWrapper {
  private readonly baseUrl = '/api/b2b/v1/integrations'
  private readonly axios: AxiosInstance

  constructor(axios: AxiosInstance) {
    this.axios = axios
  }

  private fetch<T>(
    path: string,
    params: Params = {}
  ): Promise<AxiosResponse<T>> {
    return this.axios.get<T>(this.baseUrl + path, { params })
  }

  private post<T>(
    path: string,
    data: T,
    params: Params = {}
  ): Promise<AxiosResponse<T>> {
    return this.axios.post<T>(this.baseUrl + path, data, { params })
  }

  private put<T>(
    path: string,
    data: Data,
    params: Params = {}
  ): Promise<AxiosResponse<T>> {
    return this.axios.put<T>(this.baseUrl + path, data, { params })
  }

  private patch<T>(
    path: string,
    data: Data,
    params: Params = {}
  ): Promise<AxiosResponse<T>> {
    return this.axios.patch<T>(this.baseUrl + path, data, { params })
  }

  private delete<T>(
    path: string,
    params: Params = {}
  ): Promise<AxiosResponse<T>> {
    return this.axios.delete<T>(this.baseUrl + path, { params })
  }

  public gCalendarIntegrate(
    data: gClientIntegration
  ): Promise<gClientIntegration> {
    return this.post<gClientIntegration>('/gcalendar', data).then((r) => r.data)
  }

  public yClientIntegrate(
    data: yClientIntegration
  ): Promise<yClientIntegration> {
    return this.post<yClientIntegration>('/yclients', data).then((r) => r.data)
  }
}
