


















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'

type InputType = 'text' | 'number' | 'tel' | 'email' | 'password'

@Component({
  name: 'Field',
  inheritAttrs: false,
})
export default class BaseInput extends Vue {
  showPassword = false

  @Prop({ default: () => uuidv4() })
  id!: string

  @Prop()
  value!: unknown

  @Prop({ default: false })
  isShakeError!: boolean

  @Prop({ default: 'text' })
  type!: InputType

  @Prop()
  label!: string

  @Prop()
  classWrap!: string

  @Prop({ default: false })
  labelRequered!: boolean

  @Prop({ default: false })
  group!: boolean

  @Prop()
  errorText!: string

  @Prop({ default: false })
  hasError!: boolean

  @Prop({ default: false })
  disabled!: boolean

  get initType(): string {
    if (this.type === 'password') {
      return this.showPassword ? 'text' : 'password'
    }
    return this.type
  }

  onBeforeInput(event: InputEvent): void {
    if (
      this.initType === 'number' &&
      event.data !== null &&
      event.data !== Math.abs(parseInt(event.data, 10)).toString()
    ) {
      event.preventDefault()
    }
  }

  onInput(event: InputEvent): void {
    const input = event.target as HTMLInputElement
    if (this.initType === 'number') {
      const max = input.max ? parseInt(input.max, 10) : Number.MAX_SAFE_INTEGER
      const maxLimit =
        max > Number.MAX_SAFE_INTEGER ? Number.MAX_SAFE_INTEGER : max
      const parsed = parseInt(input.value, 10)
      const normalized = (parsed > maxLimit ? maxLimit : parsed).toString()
      if (normalized === '0') {
        input.value = ''
      } else if (input.value.length && input.value !== normalized) {
        input.value = normalized
      }
    }
    this.$emit('input', input.value)
  }
}
