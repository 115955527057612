export const getItem = (key: string): any => {
  try {
    if (localStorage.getItem(key))
      return JSON.parse(localStorage.getItem(key) || '')
    else return null
  } catch (error) {
    console.log('Error getting data from localStorage', error)
    return null
  }
}
export const setItem = (key: string, data: unknown): void => {
  try {
    localStorage.setItem(key, JSON.stringify(data))
  } catch (error) {
    console.log('Error saving data in localStorage', error)
  }
}
