import axios from '@/api/axios'
import { RoomModel } from './models/RoomModel'

class RoomsService {
  async getRooms(): Promise<RoomModel[]> {
    try {
      const response = await axios.get('/api/b2b/v1/places')
      return response.data.data
    } catch (error) {
      console.log('getRooms', error)
      return error.response.data
    }
  }

  async getRoom(id: number): Promise<RoomModel> {
    try {
      const response = await axios.get('/api/b2b/v1/places/' + id)
      return response.data.data
    } catch (error) {
      console.log('getRooms', error)
      return error.response.data
    }
  }
}

export default new RoomsService()
