import axios from '@/api/axios'
import {NewsModel} from "@/core/models/NewsModel";

class NewsService {
    async getNews(): Promise<NewsModel[]> {
        try {
            const response = await axios.get('api/b2b/v1/news')
            return response.data.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log('getNews', error.message, error.response?.data)
            }
            throw error
        }
    }

    async readNews(id: number): Promise<NewsModel> {
        try {
            const response = await axios.get(`api/b2b/v1/news/${id}`)

            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.log('readNews', error.message, error.response?.data)
            }
            throw error
        }
    }

    isServiceError = axios.isAxiosError
}

export default new NewsService()
