import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { LoginModel } from '../../modules/AuthPage/models/LoginModel'
import { RegisterModel } from '@/modules/AuthPage/models/RegisterModel'
import AuthService from '../../modules/AuthPage/AuthService'

@Module({
  namespaced: true,
})
export default class AuthStore extends VuexModule {
  private isAuth = false

  @Mutation
  private setLoginUser() {
    this.isAuth = true
  }

  @Action({ rawError: true })
  async login(user: LoginModel): Promise<unknown> {
    const result = await AuthService.login(user)
    if (result.status && result.status === 'success') {
      this.context.commit('setLoginUser')
    }
    return result
  }

  @Action({ commit: 'setLoginUser' })
  register(user: RegisterModel): Promise<RegisterModel> {
    return AuthService.register(user)
  }

  get isAuthComplete(): boolean {
    return this.isAuth
  }
}
