import Vue from 'vue'

const screens = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
}

const sm = (val: number) => val >= screens.sm && val <= screens.md
const md = (val: number) => val >= screens.md && val <= screens.lg
const lg = (val: number) => val >= screens.lg && val <= screens.xl
const xl = (val: number) => val >= screens.xl

const getBreakpoint = (w: number) => {
  if (sm(w)) return 'sm'
  else if (md(w)) return 'md'
  else if (lg(w)) return 'lg'
  else if (xl(w)) return 'xl'
  else return 'all'
}

const debounce = <F extends (...args: any[]) => any>(f: F, wait: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null
  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout)
      timeout = null
    }
    timeout = setTimeout(() => f(...args), wait)
  }
  return debounced as (...args: Parameters<F>) => ReturnType<F>
}

const breakpoints = Vue.observable({
  width: window.innerWidth,
  height: window.innerHeight,
  is: getBreakpoint(window.innerWidth),
})

window.addEventListener(
  'resize',
  debounce(() => {
    breakpoints.width = window.innerWidth
    breakpoints.height = window.innerHeight
    breakpoints.is = getBreakpoint(window.innerWidth)
  }, 10),
  false
)

export default breakpoints
