import axios from '@/api/axios'
import { UserModel, UserRequisitesModel } from '@/core/models/UserModel'

class AuthService {
  async getUser(): Promise<UserModel> {
    try {
      const response = await axios.get('/api/b2b/v1/auth/user')
      const user = response.data.data as UserModel
      if (user?.phone && user.phone[0] !== '+') {
        user.phone = '+' + user.phone
      }
      return user
    } catch (error) {
      console.log(error)
      return error.response.data
    }
  }

  async updateUser(
    requisites: UserRequisitesModel
  ): Promise<UserRequisitesModel> {
    try {
      const response = await axios.post('/api/b2b/v1/requisites', {
        ...requisites,
        law_form: 'juridical',
      })
      return response.data.data
    } catch (error) {
      console.log(error)
      return error.response.data
    }
  }
}

export default new AuthService()
