import { CommonWrapper } from '@/api/CommonWrapper/CommonWrapper'
import axios from '@/api/axios'
import { AxiosInstance } from 'axios'

export class ApiWrapper {
  public readonly common
  private readonly axios: AxiosInstance = axios

  constructor() {
    this.common = new CommonWrapper(this.axios)
  }
}
