import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import NewsService from "@/core/services/NewsService";
import {NewsModel} from "@/core/models/NewsModel";

@Module({
    namespaced: true,
})
export default class NewsStore extends VuexModule {
    private news: NewsModel[] = []

    @Mutation
    private setNews(news: NewsModel[]) {
        this.news = news
    }

    @Action({ commit: 'setNews' })
    async getNews(): Promise<NewsModel[]> {
        const news = await NewsService.getNews()
        return news
    }

    get newsList(): NewsModel[] {
        return this.news
    }
}
