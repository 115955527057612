















import { Component, Vue } from 'vue-property-decorator'
import AOS from 'aos'
import 'aos/dist/aos.css'

import LandingHeader from './components/LandingHeader.vue'
import LandingInfo from './components/LandingInfo.vue'
import LandingSites from './components/LandingSites.vue'
import LandingTariffs from './components/LandingTariffs.vue'
import LandingRegister from './components/LandingRegister.vue'
import LandingStat from './components/LandingStat.vue'
import LandingFeedback from './components/LandingFeedback.vue'
import LandingFooter from './components/LandingFooter.vue'
import LandingCommission from "@/modules/LandingPageHome/components/LandingCommission.vue";
import LandingHowToGo from "@/modules/LandingPageHome/components/LandingHowToGo.vue";
import LandingOurPartners from "@/modules/LandingPageHome/components/LandingOurPartners.vue";

@Component({
  name: 'Landing',
  components: {
    LandingOurPartners,
    LandingHowToGo,
    LandingCommission,
    LandingHeader,
    LandingInfo,
    LandingSites,
    LandingTariffs,
    LandingRegister,
    LandingStat,
    LandingFeedback,
    LandingFooter,
  },
  metaInfo: {
    title: 'DЕЛИSPACE - Информация для арендодателей',
  },
})
export default class LandingPage extends Vue {
  public mounted(): void {
    this.loadPxAdhighNetScript()

    AOS.init()
  }

  public loadPxAdhighNetScript(): void {
    if (typeof __GetI === 'undefined') {
      var __GetI = []
    }
    if (typeof __GetI_domain! === 'undefined') {
      var __GetI_domain = 'px.adhigh.net'
    }
    ;(function () {
      var p = {
        type: 'VIEW',
        /* config START */
        site_id: '8007',
        product_id: '',
        product_price: '',
        category_id: '',
        pixel_id: '',
        /* config END */
      }
      __GetI.push(p)
      var domain =
        typeof __GetI_domain == 'undefined' ? 'px.adhigh.net' : __GetI_domain
      var src =
        ('https:' == document.location.protocol ? 'https://' : 'http://') +
        domain +
        '/p.js'
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = src
      var s = document.getElementsByTagName('script')[0]
      s.parentNode!.insertBefore(script, s)
    })()
  }
}
