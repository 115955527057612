import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import axios from '@/api/axios'
import RoomsService from '@/modules/MyRoomsPage/RoomsService'
import { RoomModel } from '@/modules/MyRoomsPage/models/RoomModel'

export interface FillingItemInterface {
  icon: string
  id: number
  name: string
}

export interface VisitorsItemInterface {
  min: number
  max: number
}

export interface OldPhotoInterface {
  uid: number
  url: string
  status: null | string
}

export type ProhibitionItemInterface = FillingItemInterface

export interface Condition {
  id?: number
  weekdays: number[]
  from: null | string
  to: null | string
  isVisitors: boolean
  visitors: null | VisitorsItemInterface
  price: null | number
  fullday: false | boolean
}

export interface StepOneInterface {
  name: string
  placeType: number[]
  description: string
  capacity: null | number
  area: string
  address: null | number
  active: boolean
  showSchedule: boolean
}

export interface StepTwoInterface {
  placeNumber: string
  floor: null | number
  minBooking: null | number
  bookingStep: null | number
  responsibleManagers: ''
  integrations: {
    gCalendar: {
      id: null | number
      calendarId: string
    }
    yClient: {
      apiToken: string
      login: string
      password: string
    }
    current: null | string
  }
}

export interface StepThreeInterface {
  filling: FillingItemInterface[]
  prohibitions: ProhibitionItemInterface[]
}

export interface StepFourInterface {
  youtubeLink: string
  videoUrl: string
  photos: string[] | File[]
  oldPhotos: OldPhotoInterface[]
}

export interface StepFiveInterface {
  conditions: Condition[]
}

export interface Model {
  id?: number
  StepOne: StepOneInterface
  StepTwo: StepTwoInterface
  StepThree: StepThreeInterface
  StepFour: StepFourInterface
  StepFive: StepFiveInterface
}

@Module({
  namespaced: true,
})
export default class LocationsStore extends VuexModule {
  private model: Model = {
    StepOne: {
      name: '',
      placeType: [],
      description: '',
      capacity: null,
      area: '',
      address: null,
      active: true,
      showSchedule: false,
    },
    StepTwo: {
      placeNumber: '',
      floor: null,
      minBooking: null,
      bookingStep: null,
      responsibleManagers: '',
      integrations: {
        gCalendar: {
          id: null,
          calendarId: '',
        },
        yClient: {
          apiToken: '',
          login: '',
          password: '',
        },
        current: null,
      },
    },
    StepThree: {
      filling: [],
      prohibitions: [],
    },
    StepFour: {
      youtubeLink: '',
      videoUrl: '',
      photos: [],
      oldPhotos: [],
    },
    StepFive: {
      conditions: [],
    },
  }

  private conditions: Condition[] = []

  @Mutation
  private setModel(model: Model) {
    this.model = model
  }

  @Mutation
  private resetModel() {
    this.model = {
      StepOne: {
        name: '',
        placeType: [],
        description: '',
        capacity: null,
        area: '',
        address: null,
        active: true,
        showSchedule: false,
      },
      StepTwo: {
        placeNumber: '',
        floor: null,
        minBooking: null,
        bookingStep: null,
        responsibleManagers: '',
        integrations: {
          gCalendar: {
            id: null,
            calendarId: '',
          },
          yClient: {
            apiToken: '',
            login: '',
            password: '',
          },
          current: null,
        },
      },
      StepThree: {
        filling: [],
        prohibitions: [],
      },
      StepFour: {
        youtubeLink: '',
        videoUrl: '',
        photos: [],
        oldPhotos: [],
      },
      StepFive: {
        conditions: [],
      },
    }
    this.conditions = []
  }

  @Action
  private async getModel(id: number) {
    const place = await RoomsService.getRoom(id)

    const fillings = await axios
      .get('/api/b2b/v1/features')
      .then(({ data }) => {
        return data.data as FillingItemInterface[]
      })
      .catch(({ error }) => {
        console.log(error)
        return []
      })

    if (place !== null) {
      const conditions: Condition[] = []
      const oldPhotos: OldPhotoInterface[] = []

      for (let i = 0; i < place.offers.length; i++) {
        conditions.push({
          from: place.offers[i].from,
          to: place.offers[i].to,
          fullday: place.offers[i].fullday,
          id: place.offers[i].id,
          isVisitors: !!place.offers[i].visitors,
          price: place.offers[i].price,
          visitors: place.offers[i].visitors
            ? {
                min: parseInt('' + place.offers[i].visitors?.min),
                max: parseInt('' + place.offers[i].visitors?.max),
              }
            : null,
          weekdays: place.offers[i].weekdays,
        })
      }

      for (let i = 0; i < place.images.length; i++) {
        oldPhotos.push({
          status: null,
          uid: place.images[i].uid || 0,
          url: place.images[i].url,
        })
      }

      const model = {
        id: place.id,
        StepOne: {
          name: place.name,
          placeType: place.place_types ?? [],
          description: place.description ?? '',
          capacity: place.capacity,
          area: '' + place.area,
          address: place.location_id,
          active: place.active,
          showSchedule: place.show_schedule,
        },
        StepTwo: {
          placeNumber: '',
          floor: null,
          minBooking: null,
          bookingStep: null,
          responsibleManagers: '',
          integrations: {
            gCalendar: {
              id: place.integrations?.google_calendar?.id ?? null,
              calendarId:
                place.integrations?.google_calendar?.calendar_id ?? '',
            },
            yClient: {
              apiToken: '',
              login: '',
              password: '',
            },
            current: null,
          },
        },
        StepThree: {
          filling: fillings.filter((item) => {
            for (let i = 0; i < place.features.length; i++) {
              if (place.features[i] === item.id) {
                return true
              }
            }
            return false
          }),
          prohibitions: [],
        },
        StepFour: {
          youtubeLink: place.youtube_link,
          videoUrl: '',
          photos: [],
          oldPhotos: oldPhotos,
        },
        StepFive: {
          conditions: conditions,
        },
      }

      this.context.commit('setModel', model)
      this.context.commit('setConditions', conditions)
    }
  }

  @Mutation
  private setConditions(conditions: Condition[]) {
    this.conditions = conditions
  }

  @Mutation
  private setEditCondition(args: any) {
    this.conditions = this.conditions.map((item, index) => {
      if (args.index === index) {
        return args.condition
      }
      return item
    })
  }

  @Action
  private async createPlace() {
    const features = this.model.StepThree.filling.map((item) => item.id)

    const formData = new FormData()

    const { photos } = this.model.StepFour
    const { placeType } = this.model.StepOne
    const { integrations } = this.model.StepTwo
    const { conditions } = this.model.StepFive
    const managers = [1]

    formData.append('active', String(+this.model.StepOne.active))
    formData.append('show_schedule', String(+this.model.StepOne.showSchedule))
    formData.append('name', this.model.StepOne.name)
    formData.append('description', this.model.StepOne.description)
    //formData.append('apt', 'test')
    formData.append('area', this.model.StepOne.area)
    formData.append('capacity', String(this.model.StepOne.capacity))
    //formData.append('floor', String(this.model.StepTwo.floor))
    formData.append('location_id', '' + this.model.StepOne.address)

    for (const i in placeType) {
      formData.append('place_types[]', '' + placeType[i])
    }

    if (integrations.gCalendar.id) {
      formData.append(
        'integrations[google_calendar][id]',
        '' + integrations.gCalendar.id
      )
    }
    if (integrations.gCalendar.calendarId) {
      formData.append(
        'integrations[google_calendar][calendar_id]',
        integrations.gCalendar.calendarId
      )
    }

    //formData.append('booking_quantise', '00:30')
    //formData.append('booking_min_duration', '00:30')
    //formData.append('external_code', 'ipsa')

    for (let i = 0; i < features.length; i++) {
      formData.append('features[]', String(features[i]))
    }

    for (let i = 0; i < conditions.length; i++) {
      for (let j = 0; j < conditions[i].weekdays.length; j++) {
        formData.append(
          `offers[${i}][weekdays][${j}]`,
          String(conditions[i].weekdays[j])
        )
      }
      if (conditions[i].id) {
        formData.append(`offers[${i}][id]`, String(conditions[i].id))
      }
      formData.append(`offers[${i}][from]`, String(conditions[i].from))
      formData.append(`offers[${i}][to]`, String(conditions[i].to))
      formData.append(`offers[${i}][price]`, String(conditions[i].price))
      formData.append(`offers[${i}][fullday]`, String(+conditions[i].fullday))
      if (conditions[i].isVisitors) {
        formData.append(
          `offers[${i}][visitors][min]`,
          String(conditions[i].visitors?.min ?? 1)
        )

        formData.append(
          `offers[${i}][visitors][max]`,
          String(conditions[i].visitors?.max ?? 2)
        )
      }
    }

    formData.append('youtube_link', this.model.StepFour.youtubeLink)

    for (let i = 0; i < this.model.StepFour.oldPhotos.length; i++) {
      formData.append(
        `images[${i}][uid]`,
        '' + this.model.StepFour.oldPhotos[i].uid
      )
      formData.append(
        `images[${i}][status]`,
        '' + this.model.StepFour.oldPhotos[i].status
      )
    }

    for (let i = 0; i < photos.length; i++) {
      if (photos[i] instanceof File) {
        formData.append('images[]', photos[i])
      }
    }

    /*for (let i = 0; i < managers.length; i++) {
      formData.append('managers[]', String(managers[i]))
    }*/
    const uri =
      this.model.id === undefined
        ? '/api/b2b/v1/places'
        : '/api/b2b/v1/places/' + this.model.id

    return axios
      .post(uri, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(() => {
        return {
          status: 'success',
          message:
            'Помещение станет активным и появится в каталоге после прохождения модерации.',
        }
      })
      .catch((response) => {
        let errorText = ''
        let errorMessage
        for (errorMessage in response.response.data.errors) {
          if (typeof response.response.data.errors[errorMessage] === 'string') {
            errorText += response.response.data.errors[errorMessage] + '\n'
          } else {
            errorText += response.response.data.errors[errorMessage][0] + '\n'
          }
        }
        return {
          status: 'error',
          message: errorText,
        }
      })
  }
}
