import { AxiosInstance, AxiosResponse } from 'axios'
import { Params, Data, Response } from '@/api/types'

import {Site, Filter, Partner} from './types'
import { IntegrationWrapper } from '@/api/CommonWrapper/IntegrationWrapper/IntegrationWrapper'

export * from './types'

export class CommonWrapper {
  private readonly baseUrl = '/api/common/v1'
  private readonly axios: AxiosInstance

  public readonly integration: IntegrationWrapper

  constructor(axios: AxiosInstance) {
    this.axios = axios
    this.integration = new IntegrationWrapper(axios)
  }

  private fetch<T>(
    path: string,
    params: Params = {}
  ): Promise<AxiosResponse<T>> {
    return this.axios.get<T>(this.baseUrl + path, { params })
  }

  private post<T>(
    path: string,
    data: Data,
    params: Params = {}
  ): Promise<AxiosResponse<T>> {
    return this.axios.post<T>(this.baseUrl + path, data, { params })
  }

  private put<T>(
    path: string,
    data: Data,
    params: Params = {}
  ): Promise<AxiosResponse<T>> {
    return this.axios.put<T>(this.baseUrl + path, data, { params })
  }

  private patch<T>(
    path: string,
    data: Data,
    params: Params = {}
  ): Promise<AxiosResponse<T>> {
    return this.axios.patch<T>(this.baseUrl + path, data, { params })
  }

  private delete<T>(
    path: string,
    params: Params = {}
  ): Promise<AxiosResponse<T>> {
    return this.axios.delete<T>(this.baseUrl + path, { params })
  }

  public getSitesList(): Promise<Response<Site[]>> {
    return this.fetch<Response<Site[]>>('/sites').then((r) => r.data)
  }

  public getOurPartnersList(): Promise<Response<Partner[]>> {
    return this.fetch<Response<Partner[]>>('/partners/our').then((r) => r.data)
  }

  public getFiltersList(): Promise<Response<Filter[]>> {
    return this.fetch<Response<Filter[]>>('/filters').then((r) => r.data)
  }

  public getContentBySlug(slug: string): Promise<Response<any>> {
    return this.fetch<Response<any>>('/pages/content', { slug, site: 1 }).then( (r) => r.data )
  }
}
