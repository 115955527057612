

















































































































import {Component} from 'vue-property-decorator'
import {required, email, minLength, sameAs} from 'vuelidate/lib/validators'
import {AuthMixin} from '@/mixins/auth.mixin'

import BaseButton from '@/components/base/BaseButton.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import BaseCheckbox from '@/components/forms/BaseCheckbox.vue'
import Field from '@/components/forms/Field.vue'
import Modal from '@/components/Modal.vue'

const validations = {
  registerModel: {
    name: {required, minLength: minLength(1)},
    email: {required, email},
    phone: {required},
  },
  terms: {required, sameAs: sameAs(() => true)},
}

@Component({
  name: 'LandingForm',
  components: {FormGroup, Field, BaseButton, BaseCheckbox, Modal},
  validations,
})
export default class LandingForm extends AuthMixin {
  terms = false
  showModal = false
}
