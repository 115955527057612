import { Component, Vue } from 'vue-property-decorator'
import { ErrorsModel } from '@/core/models/ErrorsModel'
import { RegisterModel } from '@/modules/AuthPage/models/RegisterModel'
import { namespace } from 'vuex-class'
const AuthStore = namespace('AuthStore')

@Component
export class AuthMixin extends Vue {
  showModal = false
  showErrorModal = false
  successMessage = ''
  errorMessage = ''
  errors: ErrorsModel | null = null
  registerModel: RegisterModel = new RegisterModel()
  terms = false

  @AuthStore.Action
  private register!: (data: RegisterModel) => Promise<RegisterModel | any>

  async submit(): Promise<any> {
    this.$v.$touch()

    if (this.$v.$invalid) {
      return false
    } else {
      const clone = Object.assign({}, this.registerModel)
      let phone = clone.phone

      if (typeof phone === 'string') {
        phone = phone.replace(/\D/g, '')
        clone.phone = +phone
      }

      const res = await this.register(clone)

      if (res.status === 200) {
        this.registerModel = new RegisterModel()
        this.$v.$reset()
        this.successMessage = res.data.message
        this.showModal = true
        this.$metrika.reachGoal('Registration')
      }

      if (res.errors) {
        this.errors = res.errors
        this.errorMessage = res.errors[0]
        this.showErrorModal = true
      }
    }
  }
}
