import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import BookingService from '@/core/services/BookingService'
import { BookingModel } from '@/core/models/BookingModel'

@Module({
  namespaced: true,
})
export default class BookingStore extends VuexModule {
  private bookings: BookingModel[] = []

  @Mutation
  private setBookings(bookings: BookingModel[]) {
    this.bookings = bookings
  }

  @Action({ commit: 'setBookings' })
  async getBookings({
    from = null,
    to = null,
  }: {
    from: Nullable<Date>
    to: Nullable<Date>
  }): Promise<BookingModel[]> {
    const bookings = await BookingService.getBookings(from, to)
    return bookings
  }

  @Action
  async addBooking({
    placeId,
    start,
    end,
  }: {
    placeId: number
    start: Date
    end: Date
  }): Promise<BookingModel> {
    const booking = await BookingService.addBooking(placeId, start, end)
    return booking
  }

  @Action
  async updateBooking({
    id,
    placeId,
    start,
    end,
  }: {
    id: number
    placeId: number
    start: Date
    end: Date
  }): Promise<BookingModel> {
    const booking = await BookingService.updateBooking(id, placeId, start, end)
    return booking
  }

  get bookingList(): BookingModel[] {
    return this.bookings
  }
}
