



































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import VueModal from '@kouts/vue-modal'
import '@kouts/vue-modal/dist/vue-modal.css'
import BaseIcon from '@/components/base/BaseIcon.vue'

type ModalType = 'success' | 'error' | 'warning'

@Component({
  name: 'Modal',
  components: { VueModal, BaseIcon },
})
export default class Modal extends Vue {
  visibleIcon = false

  @Prop()
  image!: string

  @Prop()
  type!: ModalType

  @Prop({ required: true })
  showModal!: boolean

  @Prop({ default: 'animate__fadeInDown' })
  inClass!: string

  @Prop({ default: 'animate__fadeOutDown' })
  outClass!: string

  @Prop({ default: '' })
  bgInClass!: string

  @Prop({ default: '' })
  bgOutClass!: string

  @Prop({ default: true })
  enableClose!: boolean

  get currentIcon(): string {
    switch (this.type) {
      case 'success':
        return 'success'
      case 'error':
        return 'error'
      case 'warning':
        return 'warning'
      default:
        return ''
    }
  }

  @Emit('after-open')
  afterOpen(): void {
    setTimeout(() => {
      this.visibleIcon = true
    }, 300)
  }

  beforeOpen(): void {
    document.documentElement.style.overflowY = 'hidden'
  }

  beforeClose(): void {
    document.documentElement.style.overflowY = ''
  }
}
