import axios from '@/api/axios'

class LocationsService {
  async getLocations(): Promise<any[]> {
    try {
      const response = await axios.get('/api/b2b/v1/locations')
      return response.data.data
    } catch (error: any) {
      return error.response.data
    }
  }

  async getLocation(id: number): Promise<any> {
    try {
      const response = await axios.get('/api/b2b/v1/locations/' + id)
      return response.data.data
    } catch (error: any) {
      return error.response.data
    }
  }

  async getRecommendedLocations(
    query: string,
    cityName: string
  ): Promise<any[]> {
    const url =
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
    const token = '1a70756a7448ae9c5ff5a6702a6c876ce182a450'

    interface OptionsInterface {
      method: string
      mode: string | any
      headers: {
        'Content-Type': string
        Accept: string
        Authorization: string
      }
      body: string
    }

    const locations = [
      {
        city: cityName,
      },
    ]

    const options: OptionsInterface = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + token,
      },
      body: JSON.stringify({ query: query, locations: locations }),
    }

    let recommendedLocations = {
      suggestions: [],
    }

    await fetch(url, options)
      .then((response) => response.text())
      .then((result) => {
        recommendedLocations = JSON.parse(result)
      })
      .catch((error) => console.log('error', error))

    return recommendedLocations.suggestions
  }
}

export default new LocationsService()
