export class GpsCoordsModel {
  lat = 0
  lng = 0
}

export class MetroStation {
  id = 0
  name = ''
  color = ''
  city = 0
  main = false
  time: Nullable<number> = null
}

export class LocationModel {
  id?: number | null = null
  name = ''
  address = ''
  addressLegacy?: any = ''
  phone = ''
  whatsapp = ''
  telegram = ''
  active = true
  parkingInfo = ''
  wayDirections = ''
  cityId: number | null = null
  areaId: number | null = null
  gpsCoords = new GpsCoordsModel()
  metroStations: MetroStation[] = []
  addressData?: any = null
}
