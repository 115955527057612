import axios from '@/api/axios'
import { PlanModel } from '@/core/models/PlanModel'

class PremiumService {
  async getPlans(): Promise<PlanModel[]> {
    try {
      const response = await axios.get('api/b2b/v1/premium/plans')
      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('getPlans', error.message, error.response?.data)
      }
      throw error
    }
  }

  isServiceError = axios.isAxiosError
}

export default new PremiumService()
