import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import CitiesService from '@/core/services/CitiesService'
import { CityModel } from '@/core/models/CityModel'

@Module({
  namespaced: true,
})
export default class CitiesStore extends VuexModule {
  private cities: CityModel[] = []

  @Mutation
  private setCities(cities: CityModel[]) {
    this.cities = cities
  }

  @Action({ commit: 'setCities' })
  getCities(): Promise<CityModel[]> {
    return CitiesService.getCities()
  }

  get cityList(): CityModel[] {
    return this.cities
  }
}
