export class RoomOfferVisitorsModel {
  0 = ''
  min: number | null = null
  max: number | null = null
}
export class RoomOfferModel {
  id?: number = 0
  from = ''
  to = ''
  price = 0
  fullday = false
  weekdays: number[] = []
  visitors?: RoomOfferVisitorsModel
}

export class OldPhotoModel {
  status?: string
  uid: number | null = null
  url = ''
}

export class IntegrationsModel {
  google_calendar: GoogleCalendarModel | null = null
  yclients = null
}

export class GoogleCalendarModel {
  id?: number
  calendar_id?: string
}

export class RoomViewsModel {
  id?: number = 0
  views?: number = 0
}

export class RoomModel {
  id?: number = 0
  active = false
  show_schedule = false
  name = ''
  description?: null
  area: number | null = null
  capacity: number | null = null
  address = ''
  title = ''
  youtube_link?: string = ''
  floor?: string = ''
  apt?: string = ''
  location_id: number | null = null
  place_types?: number[] = []
  locations?: number[] = []
  booking_quantise?: string = ''
  booking_min_duration?: string = ''
  external_code?: string = ''
  features: number[] = []
  offers: RoomOfferModel[] = []
  images: OldPhotoModel[] = []
  integrations: IntegrationsModel = new IntegrationsModel()
  managers?: string[] = []
  description_short?: string = ''
  description_full?: string = ''
  verified?: boolean = false
  views?: number = 0
  calls?: number = 0
  callbacks?: number = 0
}
