import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import PlanService from '@/core/services/PlanService'
import { PlanModel } from '@/core/models/PlanModel'

@Module({
  namespaced: true,
})
export default class PlansStore extends VuexModule {
  private plans: PlanModel[] = []

  @Mutation
  private setPlans(plans: PlanModel[]) {
    this.plans = plans
  }

  @Action({ commit: 'setPlans' })
  async getPlans(): Promise<PlanModel[]> {
    const rooms = await PlanService.getPlans()
    return rooms
  }

  get planList(): PlanModel[] {
    return this.plans
  }
}
