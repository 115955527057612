import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import {
  RoomModel,
  RoomViewsModel,
} from '@/modules/MyRoomsPage/models/RoomModel'
import RoomsService from '@/modules/MyRoomsPage/RoomsService'
import { setItem, getItem } from '@/utils/persistanceStorage'

@Module({
  namespaced: true,
})
export default class RoomsStore extends VuexModule {
  private rooms: RoomModel[] = []
  private roomsViews: RoomViewsModel[] = []

  get previousViewsById() {
    return (id: number): number | undefined => {
      return this.roomsViews.find((room) => room.id === id)?.views
    }
  }

  @Mutation
  private setRooms(rooms: RoomModel[]) {
    this.rooms = rooms
  }

  @Mutation
  private setRoomsViews(roomsViews: RoomViewsModel[]) {
    this.roomsViews = roomsViews
  }

  @Action({ commit: 'setRooms' })
  async getRooms(): Promise<RoomModel[]> {
    this.context.commit('setRoomsViews', getItem('roomsViews') || [])
    const rooms = await RoomsService.getRooms()
    setItem(
      'roomsViews',
      rooms.map((room) => {
        const roomViews = new RoomViewsModel()
        roomViews.id = room.id
        roomViews.views = room.views
        return roomViews
      })
    )
    return rooms
  }

  get roomList(): RoomModel[] {
    return this.rooms
  }
}
