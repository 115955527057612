
















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'

type BaseCheckboxType = 'checkbox' | 'radio'

@Component({
  name: 'Checkbox',
})
export default class BaseCheckbox extends Vue {
  @Prop({ default: () => `checkbox-${uuidv4()}` })
  uid!: string

  @Prop({ default: '' })
  label!: string

  @Prop({ default: null })
  value!: any

  @Prop({ default: null })
  dValue!: any

  @Prop({ default: '' })
  classesLabel!: string

  @Prop({ default: '' })
  classesInput!: string

  @Prop({ default: 'checkbox' })
  type!: BaseCheckboxType

  @Prop({ default: '' })
  errorText!: string

  @Prop({ default: '' })
  name!: string

  @Prop()
  red!: boolean

  @Prop()
  gray!: boolean

  @Prop()
  purpleWhite!: boolean

  @Prop({ default: false })
  disabled!: boolean

  @Prop(Boolean)
  switcher!: boolean

  @Prop({ default: null })
  checked!: boolean

  get listeners(): any {
    return {
      change: (event: any) => {
        this.toggleValue(event)
      },
    }
  }

  get isChecked(): any {
    if (this.checked !== null) {
      return this.checked
    }
    return this.isArrayd() ? this.isArrayIncludes() : this.value
  }

  toggleValue(event: any): void {
    if (this.isArrayd()) {
      this.setArray()
    } else if (typeof this.dValue === 'string') {
      this.setValueString()
    } else {
      if (this.type === 'radio') {
        this.$emit('input', this.value)
      } else {
        this.$emit('input', !this.value)
      }
      this.$emit('change', event)
    }
  }
  setArray(): void {
    const value = this.value.slice(0)

    if (this.isArrayIncludes()) {
      value.splice(value.indexOf(this.dValue), 1)
      this.$emit('input', value)
      this.$emit('change', value)
    } else {
      value.push(this.dValue)
      this.$emit('input', value)
      this.$emit('change', value)
    }
  }
  setValueString(): void {
    if (this.value === this.dValue) {
      this.$emit('input', null)
      this.$emit('change', null)
    } else {
      this.$emit('input', this.dValue)
      this.$emit('change', this.dValue)
    }
  }

  isArrayIncludes(): any {
    const model = this.value
    const value = this.dValue

    if (Array.isArray(model)) return model.includes(value)
  }

  isArrayd(): any {
    return Array.isArray(this.value)
  }
}
