

























import {Component, Vue} from 'vue-property-decorator'
import LandingTitle from './LandingTitle.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import breakpoints from "@/plugins/breakpoints";
import {Partner} from "@/api/CommonWrapper/types";
import LandingPageService from "@/modules/LandingPageHome/LandingPageService";

@Component({
  name: 'LandingOurPartners',
  components: {
    Swiper,
    SwiperSlide,
    LandingTitle,
    BaseButton,
  },
})
export default class LandingOurPartners extends Vue {


  partners: Partner[] = []

  get isMobile(): boolean {
    return breakpoints.width < 768
  }

  get slidesPerViews(): number {
    return breakpoints.width < 768 ? 1 : 5
  }

  private async created() {
    this.partners = await LandingPageService.getOurPartners()
  }


  swiperOptions = {
    slidesPerView: this.slidesPerViews,
    spaceBetween: 16,
    pagination: {
      el: '.LandingOurPartners__pagination',
      clickable: true,
    },
  }


}
