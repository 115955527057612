





































import {Component, Prop, Vue} from 'vue-property-decorator'
import LandingTitle from "./LandingTitle.vue";

@Component({
  name: 'LandingTariffs',
  components: {LandingTitle}
})
export default class LandingTariffs extends Vue {
  @Prop()
  small!: boolean
  three_month_price_for_address = '3 980'
  three_month_price = '1 990'
  premium_price = '1 600'
  premium_price_banner = '3 000'

  get tariffs() { return [
    {
      id: 1,
      title: 'Стандартный',
      price: this.three_month_price.toString() + ' ₽ /3 мес.',
      for: 'за один адрес',
      description: 'Стоимость размещения объявления по одному адресу в любом разделе (лофты/кабинеты/спорт/коворкинг/бьюти/залы)<br/>Количество площадок по одному адресу не ограничено'
    },
    {
      id: 2,
      title: 'Все разделы',
      price: this.three_month_price_for_address.toString() + ' ₽ /3 мес.',
      for: 'за один адрес',
      description: 'Хотите разместить объявление в 2-х и более разделах  и показываться по большему количеству поисковых запросов<br/>' +
          'Количество площадок по одному адресу не ограничено'
    },
    {
      id: 3,
      title: 'Премиум',
      price: this.premium_price.toString() + ' ₽/мес.',
      for: 'за одно помещение',
      description: 'Ваша площадка будет в топе выдачи на нашем маркетплейсе. <br/>' + 'Оплачиваются по желанию дополнительно к стандартному тарифу.'
    },
    {
      id: 4,
      title: 'Супер премиум',
      price: this.premium_price_banner.toString() + ' ₽ / мес.',
      for: 'за одно помещение',
      description: 'Хотите привлечь максимум внимания к своей площадке - выбирайте супер-премиум тариф (широкое объявление)<br/>' +
          'Оплачиваются по желанию дополнительно к стандартному тарифу.'
    },
  ]}

  private async created() {
    const content = await this.$api.common.getContentBySlug('/landing_price')
    if (content.data) {
      const data = content.data
      if (data.bsteps_content) {
        const content = data.bsteps_content
        if (content.three_month_price_for_address) {
          this.three_month_price_for_address = content.three_month_price_for_address
        }
        if (content.three_month_price) {
          this.three_month_price = content.three_month_price
        }
        if (content.premium_price) {
          this.premium_price = content.premium_price
        }
        if (content.premium_price_banner) {
          this.premium_price_banner = content.premium_price_banner
        }
      }
    }

  }
}
