








































import { Component, Vue } from 'vue-property-decorator'
import LandingTitle from './LandingTitle.vue'

@Component({ name: 'LandingStat', components: { LandingTitle } })
export default class LandingStat extends Vue {

  list = [
    {
      id: 1,
      title: 'Помещений на сайте',
      count: 351,
      color: '#ff002c',
      shadow: '0 20px 40px 0 rgba(255, 0, 44, 0.35)',
    },
    {
      id: 2,
      title: 'Посетителей',
      count: 9312,
      color: '#52bfac',
      shadow: '0 20px 40px 0 rgba(82, 191, 172, 0.35)',
    },
    // {
    //   id: 3,
    //   title: 'Кол-во <br/>показов',
    //   count: 16348,
    //   color: '#2b61d2',
    //   shadow: '0 20px 40px 0 rgba(43, 97, 210, 0.35)',
    // },
    {
      id: 4,
      title: 'Кол-во обращений от клиентов',
      count: 1862,
      color: '#ffbd00',
      shadow: '0 20px 40px 0 rgba(255, 189, 0, 0.35)',
    },
  ]
  private async created() {
    const content = await this.$api.common.getContentBySlug('/landing_price')
    if (content.data) {
      const data = content.data
      if (data.bsteps_content) {
        const content = data.bsteps_content
        if (content.places_count) {
          this.list[0].count = content.places_count
        }
        if (content.consumers_count) {
          this.list[1].count = content.consumers_count
        }
        // if (content.views_count) {
        //   this.list[2].count = content.views_count
        // }
        if (content.avg_calls_count) {
          this.list[3].count = content.avg_calls_count
        }

      }
    }
  }

}
