

















































import {Component, Vue} from 'vue-property-decorator'
import LandingTitle from './LandingTitle.vue'
import BaseButton from '@/components/base/BaseButton.vue'

@Component({
  name: 'LandingHowToGo',
  components: {
    LandingTitle,
    BaseButton,
  },
})
export default class LandingHowToGo extends Vue {

  steps = [
    {
      id: 1,
      title: 'Зарегистрируйтесь',
      subtitle: 'Заполните максимальное количество информации об объекте. Разместите красивые фото'
    },
    {
      id: 2,
      title: 'Пополните счет',
      subtitle: 'В вашем личном кабинете. Деньги за тариф спишутся автоматически'
    },
    {
      id: 3,
      title: 'Подключите премиум тариф',
      subtitle: 'Для быстрого достижения эффекта - оплатите премиум тариф (по желанию)'
    },
    {
      id: 4,
      title: 'Получайте клиентов от DEЛИSPACE',
      subtitle: ''
    },
  ]

}
