import Vue from 'vue'
import Meta from 'vue-meta'
import Vuelidate from 'vuelidate'
import vueBemCn from 'vue-bem-cn'
import VueMask from 'v-mask'
import PortalVue from 'portal-vue'
import vClickOutside from 'v-click-outside'
import VueStickyDirective from '@renatodeleao/vue-sticky-directive'
import ResizeSensor from 'resize-sensor'
import lineClamp from 'vue-line-clamp'
import VueTippy from 'vue-tippy/dist/vue-tippy.esm'
import VueApexCharts from 'vue-apexcharts'
import YmapPlugin from 'vue-yandex-maps'
import VueYandexMetrika from 'vue-yandex-metrika-ts'
import { ApiWrapper } from '@/api/ApiWrapper'

import App from './App.vue'
import router from './router'
import store from './store'

// Dependency of @renatodeleao/vue-sticky-directive
declare global {
  interface Window {
    ResizeSensor: unknown
    ymaps: any
  }
}
window.ResizeSensor = ResizeSensor

// Declare $api in vue.
Vue.prototype.$api = new ApiWrapper()

declare module 'vue/types/vue' {
  interface Vue {
    $api: ApiWrapper
  }
}

import 'animate.css'

Vue.config.productionTip = false
Vue.use(Meta)
Vue.use(Vuelidate)
Vue.use(vueBemCn)
Vue.use(VueMask)
Vue.use(PortalVue)
Vue.use(vClickOutside)
Vue.use(VueStickyDirective)
Vue.use(lineClamp)
Vue.use(VueTippy, {
  arrow: true,
  animation: 'fade',
  duration: 200,
})
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueYandexMetrika, {
  id: 66267019,
  router: router,
  env: 'production',
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
})

const settings = {
  apiKey: process.env.VUE_APP_API_KEY_YANDEX,
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1',
}

Vue.use(YmapPlugin, settings)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
